import React from "react"
import * as fcl from "@onflow/fcl"
import { Router as BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PubSub from 'pubsub-js';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { SolarSystemLoading } from 'react-loadingg';
import { Login, Dashboard, FetchCreators, AdminForgotPassword, CreatorsList, FetchArts, AdminDetail, MarketStatus, Settings, FetchSale, MintArtDetail, ProcessBlock, FetchEvents, FetchuserDetail, AdminAccounts, AddAccounts, EditAccounts, AdminConfiguration, AddNotes, FetchAirdrops, FetchAirdropDetail, FetchAirdropUsers, MidwestFetchEvents, ModwestAddEvent, MidwestEditEvent, MidwestFetchEventsGallery, ResetAdminPassword, FetchReports, FetchTransactionHistory, FetchTransactionDetail } from './admin/Components'
import { Art } from "components/art";
import { SignIn } from "components/sign-in";
import { Drops } from "components/drop";
import { Creators } from "components/creators";
import { PageNotFound } from "components/page-not-found";
// import { AppLayoutRoute } from "components/layouts/app-layout-route";
import { ArtUploadForm } from "components/art-uploadform";
import { ProfilePage } from "components/profile-page/profile-page";
import { AdminLayoutRoute } from './admin/Layout'
import { CreatorCollection } from "components/creatorCollection";
import { CreatorDetail } from './components/creatorDetail'
import history from "helpers/history";
import { AppLayout } from "components/layouts/app-layout";
import { AppLayoutRoute } from "components/layouts/app-layout-route";
import { Content } from "components/content";
import { MyWallet } from "components/myWallet";
import { DetailWallet } from "components/detailMyWallet";
import { isAdminLogin, isConnectWallet, isUserLogin, isWebLogin, Setupflowuiloader, toastr } from "helpers";
import { userServices } from "services";
import { connect } from "react-redux";
import { userAction } from "store/actions";
import { MarketDetail } from "components/marketDetail";
import { ComingSoon } from "components/comingSoon";
import { Register } from "components/register";
import { LoginWeb } from "components/login";
import { ForgotPassword } from "components/forgotPassword";
import { Verification } from "components/verificationLayout";
import { FetchUsers, UploadUsers } from "admin/Components/Users";
import { ChangePassword } from "components/changePassword"
import { CollectorDetail } from "components/collectorDetail";
import { FetchUploadedUsers } from "admin/Components/Users/FetchUploadUser";
import { CheckSite } from 'admin/CheckSite'
import { Discover } from "components/discoverPage";
import { OwnerArt } from "components/OwnerArt";
import { AdminDiscover } from "admin/Components/Discover";
import { Maintenance } from "components/maintenance";
import { Collectors } from "components/collectors";
import { SocialUserProfileSetup } from "components/social-user-profile-setup";
import { FundWalletPage } from "components/fund-wallet-page";
import { ArtActivity1 } from "components/art-activity/art-activity-1";
import { ArtActivity2 } from "components/art-activity/art-activity-2";
import { DiscoverVersion2 } from "components/discover-v2";
import { MintStatus } from "components/mintStatus";
import { SaleListProgress } from "components/sale-list-progress/sale-list-progress";
import { DownloadArts } from "components/download";
import { AirDrops } from "components/airdrop";
import { Faq } from "components/faq";
import { About } from "components/about";
import { Contact } from "components/contact";
import { Sxsw } from "components/sxsw";
import { Terms } from "components/terms";
import { Privacy } from "components/privacy";
import { Tags } from "admin/Components/AdminConfig/Tags";
import { TagView } from "components/tag-view";
import { AirDropDetail } from "components/airdropDetail";
import { OTPVerifivation } from "components/otpVerification";
import { ConnectWallet } from "components/connectWalletPage";
import { TestPage } from "components/testPage";
import { MidWestCon } from "components/mid-west-con";
import { COB } from "components/COB";
import { Category } from "admin/Components/Category/Category";
import { NYC } from "components/NYC";
import { AdminTrending } from "admin/Components/Trending";
import { SignupAirdrop } from "components/signupWithAirdrop";
import { MusicTheme } from "components/musictheme";
import { AddEvents } from "admin/Components/EventSettings.js/AddEvents";
import { FetchArtDetail } from "admin/Components/Arts/FetchArtDetail";
import { FetchMintSlots } from "admin/Components/Mintslot/FetchMintslot";
import { CategoryView } from "components/category-view";
import { FetchDrops } from "admin/Components/Drops";
import { MidwestAddEventGallery } from "admin/Components/Midwestcon/AddEventGallery";
import { CreatorStore } from "components/CreatorStore/CreatorStore";
import { CreatorProfileForm } from "components/CreatorStore/CreatorProfile";
import { StoreLayoutRoute } from "components/CreatorStore/StoreLayoutRoute";
import { AddCollections } from "components/CreatorStore/AddCollections";
import { StoreAddDrops } from "components/CreatorStore/AddDrops";
import { StoreSaleHistory } from "components/CreatorStore/SaleHistory";
import { StoreListingArts } from "components/CreatorStore/ListingArt";
import { StoreDrops } from "components/CreatorStore/Drops";
import { StoreWithdrawListing } from "components/CreatorStore/WithdrawArt";
import { BannerCollection } from "components/CreatorStore/BannerCollections";
import { AddConditions } from "components/CreatorStore/AddConditions";
import { MoveTokens } from "components/CreatorStore/MoveTokens";
import { AirdropInvitePage } from "components/airdropInvitePage";
import { DiscoverV3 } from "components/discover-v3";
import { DALanding } from "components/da-landing";
import { FavoutiteView } from "components/favouriteList";
import { DapperListing } from "components/dapperListing";
import { AddUserAccountDetail } from "components/UserAccount/AddAccountDetail";
import { ArtTransactionHistory } from "components/UserAccount/TransactionHistory";
import { DynamicSections } from "components/CreatorStore/DynamicSections";
import { MetaTags } from "components/CreatorStore/Metatags";
import { FetchStores } from "admin/Components/Store/FetchStores";
import { FetchStoreDetail } from "admin/Components/Store/FetchStoreDetail";
import { FetchStoreSales } from "admin/Components/Store/FetchSalesReport";
import { AirdropGuestPage } from "components/airdropGuestPage/guest";
import { WelcomeUser } from "components/airdropGuestPage/welcomeuser";
import { ArtDetailPage } from "components/artdetail-view/art-detail";
import { CreatorProfile } from "components/profile-page/creator-profile";
import { CollectorProfile } from "components/profile-page/collector-profile";
import { HuntLanding } from "components/hunt-landing";
import { FetchBadges } from "admin/Components/Badges/FetchBadges";
import { CreatorsLandingPage } from "components/Creators-landing-page";
import { FetchAirdropMissedTokens } from "admin/Components/Airdrops/AirdropEntries";
import { StorePurchaseData } from "./components/CreatorStore/PurchaseData";

class AuthCluster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                loggedIn: null
            },
            loader: false,
            flowloader : false
        }
        console.log('%c App: %cInitialized app!', 'background:#007bff; color:#fff; padding:0 .6 0 0; border-radius: 5px;', 'color: default');
    }

    SWUpdateHandler(SWRegistration) {
        // console.log('Service worker update function');
        if (SWRegistration && SWRegistration.waiting) {

            toastr.appUpdateAlert(() => {

                // Check if a service worker waiting to be updated.
                // This check is necessary because when user
                // opens the app in multiple tabs, the popup is
                // shown in every open tab. And when user clicks the
                // button on one tab the service worker gets updated
                // But the popup on other tabs remain and when user
                // clicks on one of the remaining popups the application
                // throws an error since there are no service workers
                // waiting to be updated. 
                if (SWRegistration && SWRegistration.waiting) {
                    console.log('%c Service woker messaage: %cThere are updates waiting!', 'background:#007bff; color:#fff; padding:0 .6 0 0; border-radius: 5px;', 'color: default');
                    if ('serviceWorker' in navigator) {
                        navigator.serviceWorker.addEventListener("controllerchange", (event) => {
                            console.log('%c Service woker messaage: %cNew service worker took control. Reloading...', 'background:#007bff; color:#fff; padding:0 .6 0 0; border-radius: 5px;', 'color: default');
                            window.location.reload();
                            console.log('%c Service woker messaage: %cUpdated!', 'background:#007bff; color:#fff; padding:0 .6 0 0; border-radius: 5px;', 'color: default');
                        });
                    }
                    SWRegistration.waiting.postMessage({ type: 'SKIP_WAITING' });
                } else {
                    console.log('%c Service woker messaage: %cThere are no new updates waiting!', 'background:#007bff; color:#fff; padding:0 .6 0 0; border-radius: 5px;', 'color: default');
                    window.location.reload();
                }
            });

        }
    }

    componentDidMount() {
        // console.log("redirect path1", this.props)

        // document.addEventListener('contextmenu', event => event.preventDefault());

        // this.props.getUSD()
        
        fcl.currentUser().subscribe((user) => {
            this.setState({
                user
            });
        })
        this.subcribe = PubSub.subscribe('msg', (msg, data) => {
            this.setState({ loader: data });
        });
        PubSub.subscribe('flow', (msg, data) => {
            this.setState({ flowloader: data });
        });
        // Register service worker to enable PWA
        serviceWorkerRegistration.register({
            onUpdate: (registration) => {
                this.SWUpdateHandler(registration);
            }
        })
    }


    render() {
        return (
            <div>
                <BrowserRouter history={history}>
                    <Switch>
                        {/* <Route exact path="/">
                            <Redirect to="/" />
                        </Route> */}
                        <Route exact path="/auth">
                            <Redirect to="/discover" />
                        </Route>
                        {!isAdminLogin() && <Route exact path="/admin/stats">
                            <Redirect to="/admin/login" />
                        </Route>}
                        {/* <Route exact path="/drops">
                            <Redirect to="/comingsoondrop" />
                        </Route>
                        <Route exact path="/arts">
                            <Redirect to="/comingsoonart" />
                        </Route>
                        <Route exact path="/creators">
                            <Redirect to="/comingsooncreator" />
                        </Route> */}
                        <Route exact path='/mstatus' component={CheckSite}></Route>
                        {isAdminLogin() && <Route exact path='/admin/stats' component={CheckSite}></Route>}

                        <Route exact path='/admin/login' component={Login}></Route>
                        <AdminLayoutRoute path='/admin/dashboard' component={Dashboard}></AdminLayoutRoute>
                        <AdminLayoutRoute path='/admin/uploadBulk' component={UploadUsers}></AdminLayoutRoute>
                        <AdminLayoutRoute path='/admin/uploadUser' component={FetchUploadedUsers}></AdminLayoutRoute>

                        <AdminLayoutRoute path='/admin/artsList' component={FetchArts}></AdminLayoutRoute>
                        <AdminLayoutRoute path='/admin/discover' component={AdminDiscover}></AdminLayoutRoute>
                        <AdminLayoutRoute path='/admin/settings' component={Settings}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/accounts" component={AdminAccounts}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/add/accounts" component={AddAccounts}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/edit/:id/account" component={EditAccounts}></AdminLayoutRoute>

                        <AdminLayoutRoute path='/admin/creatorsApplications' component={FetchCreators}></AdminLayoutRoute>
                        <AdminLayoutRoute path='/admin/users' component={FetchUsers}></AdminLayoutRoute>
                        {/* <AdminLayoutRoute path='/admin/creators' component={CreatorsList}></AdminLayoutRoute> */}
                        <AdminLayoutRoute path='/admin/adminStatus' component={AdminDetail}></AdminLayoutRoute>
                        <AdminLayoutRoute path='/admin/marketStatus' component={MarketStatus}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/saleDetail" component={FetchSale}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/mintdetail" component={MintArtDetail}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/processBlock" component={ProcessBlock}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/eventHistory" component={FetchEvents}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/userDetail/:id" component={FetchuserDetail}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/priorityCreator" component={AdminConfiguration}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/tags" component={Tags}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/announcement" component={AddNotes}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/airdrops" component={FetchAirdrops}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/airdropDetail/:id" component={FetchAirdropDetail}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/category" component={Category}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/trending" component={AdminTrending}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/fetch-user-air-drops" component={FetchAirdropUsers}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/addevents" component={AddEvents}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/artDetail/:id" component={FetchArtDetail}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/mintslots" component={FetchMintSlots}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/dropsList" component={FetchDrops}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/midwestconEventList" component={MidwestFetchEvents}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/midwestconEvent/add" component={ModwestAddEvent}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/midwestconEvent/edit/:id" component={MidwestEditEvent}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/midwestEventsGalleryList" component={MidwestFetchEventsGallery}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/midwestEventsGallery/add" component={MidwestAddEventGallery}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/:id/change-password" component={ResetAdminPassword}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/reports" component={FetchReports}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/transactions" component={FetchTransactionHistory}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/transactionDetail/:id" component={FetchTransactionDetail}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/stores" component={FetchStores}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/store/:uuid" component={FetchStoreDetail}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/store-Report/:uuid" component={FetchStoreSales}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/badges" component={FetchBadges}></AdminLayoutRoute>
                        <AdminLayoutRoute path="/admin/missed-tokens" component={FetchAirdropMissedTokens}></AdminLayoutRoute>

                        <Route path='/admin/forgotPassword' component={AdminForgotPassword}></Route>
                        <AppLayoutRoute exact path="/" component={DALanding} isLoggedIn={this.state.user.loggedIn} />

                        <AppLayoutRoute path="/batches/drops/progress" component={SaleListProgress} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/drops" component={Drops} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute exact path="/arts" component={Art} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/mint" component={ArtUploadForm} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/creator/:address" component={CreatorProfile} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/art/tag/:tagName" component={TagView} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/art/:id" component={ArtDetailPage} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/creators" component={Creators} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/collector/:id" component={CollectorProfile} isLoggedIn={this.state.user.loggedIn} />
                        {isWebLogin() && <AppLayoutRoute path="/profile" component={ProfilePage} isLoggedIn={this.state.user.loggedIn} />}
                        {/* <AppLayoutRoute path="/terms" component={Content} isLoggedIn={this.state.user.loggedIn} /> */}
                        <AppLayoutRoute path="/wallet" component={MyWallet} isLoggedIn={this.state.user.loggedIn} />
                        {isWebLogin() && <AppLayoutRoute path="/list/:id" component={DapperListing} isLoggedIn={this.state.user.loggedIn} />}
                        {/* <AppLayoutRoute path="/marketDetail/:id" component={MarketDetail} isLoggedIn={this.state.user.loggedIn} /> */}
                        <AppLayoutRoute path="/comingsoon" component={ComingSoon} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/discover" component={CreatorsLandingPage} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/airdrops" component={AirDrops} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/faq" component={Faq} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/about" component={About} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/contact" component={Contact} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/sxsw22" component={Sxsw} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/terms" component={Terms} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/privacy" component={Privacy} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/airdrop/:id" component={AirDropDetail} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/useraccount" component={AddUserAccountDetail} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/transactions" component={ArtTransactionHistory} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/hunt-landing" component={HuntLanding} isLoggedIn={this.state.user.loggedIn} />
                        {/* <AppLayoutRoute path="/creators-landing" component={CreatorsLandingPage} isLoggedIn={this.state.user.loggedIn} /> */}

                        {isWebLogin() && <AppLayoutRoute path="/fund-wallet" component={FundWalletPage} isLoggedIn={this.state.user.loggedIn} />}

                        <Route path="/maintenance" component={Maintenance} />
                        {/* <Route path="/mwc" component={MidWestCon} /> */}
                        <AppLayoutRoute path="/mintStatus/:artname+" component={MintStatus} isLoggedIn={this.state.user.loggedIn} />
                        <Route path="/download/art/:tokenId" component={DownloadArts} />
                        <Route path="/download/mimetype/:tokenId" component={DownloadArts} />

                        <AppLayoutRoute path="/art-activity-1" component={ArtActivity1} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/art-activity-2/:id" component={ArtActivity2} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/collectors" component={Collectors} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/connect-wallet" component={ConnectWallet} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute exact path="/arts/category/:name" component={CategoryView} isLoggedIn={this.state.user.loggedIn} />
                        {/* <AppLayoutRoute path="/discoverv3" component={DiscoverV3} isLoggedIn={this.state.user.loggedIn} /> */}
                        <AppLayoutRoute exact path="/favourites" component={FavoutiteView} isLoggedIn={this.state.user.loggedIn} />

                        <AppLayoutRoute path="/testing" component={TestPage} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/cob" component={COB} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/NYC" component={NYC} isLoggedIn={this.state.user.loggedIn} />
                        <StoreLayoutRoute path="/creatorstore" component={CreatorStore} />
                        <StoreLayoutRoute path="/store/storeprofile" component={CreatorProfileForm}/>
                        <StoreLayoutRoute path="/store/section" component={AddCollections}/>
                        <StoreLayoutRoute path="/store/adddrops" component={StoreAddDrops}/>
                        <StoreLayoutRoute path="/store/salehistory" component={StoreSaleHistory}/>
                        <StoreLayoutRoute path="/store/purchasedata" component={StorePurchaseData}/>
                        <StoreLayoutRoute path="/store/list/:id" component={StoreListingArts}/>
                        <StoreLayoutRoute path="/store/storedrops" component={StoreDrops}/>
                        <StoreLayoutRoute path="/store/art/:id" component={StoreWithdrawListing}/>
                        <StoreLayoutRoute path="/store/banner" component={BannerCollection}/>
                        <StoreLayoutRoute path="/store/conditions" component={AddConditions}/>
                        <StoreLayoutRoute path="/store/movenfts" component={MoveTokens}/>
                        <StoreLayoutRoute path="/store/dynamicsection" component={DynamicSections}/>
                        <StoreLayoutRoute path="/store/metatags" component={MetaTags}/>


                        {/* <AppLayoutRoute path="/musictheme" component={MusicTheme} isLoggedIn={this.state.user.loggedIn} /> */}

                        <AppLayoutRoute path="/signup" component={Register} isLoggedIn={this.state.user.loggedIn} />
                        {<AppLayoutRoute path="/social-login/profile-setup" component={SocialUserProfileSetup} isLoggedIn={this.state.user.loggedIn} />}

                        {<AppLayoutRoute path="/login" component={LoginWeb} isLoggedIn={this.state.user.loggedIn} />}
                        <AppLayoutRoute path="/forgotPassword" component={ForgotPassword} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/registration/user/:userId/verify" component={OTPVerifivation} isLoggedIn={this.state.user.loggedIn} />

                        <AppLayoutRoute path="/user/:uuid/change-password" component={ChangePassword} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/invitee-register/:code" component={SignupAirdrop} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/auth/invitee-register/:code" component={AirdropInvitePage} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/auth/invitee-guest/:token" component={AirdropGuestPage} isLoggedIn={this.state.user.loggedIn} />
                        <AppLayoutRoute path="/auth/event-guest/:uuid" component={WelcomeUser} isLoggedIn={this.state.user.loggedIn} />

                        <Route>
                            <PageNotFound />
                        </Route>
                    </Switch>
                </BrowserRouter>
                {this.state.loader ?
                    <div className="loader"><SolarSystemLoading /></div> :
                    <div className="loader-hide"><SolarSystemLoading /></div>
                }
                {this.state.flowloader ? 
                <Setupflowuiloader/> : ''
                }
            </div>

        );
    }

}

const mapStateToProps = (state) => {
    return {}
}
const actionCreators = {
    getUSD: userAction.getUSD
}
const connectAuth = connect(mapStateToProps, actionCreators)(AuthCluster)
export { connectAuth as AuthCluster }
