import {config} from "@onflow/fcl"

let BASE_PATH = "https://devapi.disrupt.art";
let API_PATH = `${BASE_PATH}/api/v1`;

export const configPath = {
  baseUrl:BASE_PATH,
  apiUrl:API_PATH,
  imageAssets: ((process.env.REACT_APP_PUBLIC_ASSET_PATH && process.env.REACT_APP_PUBLIC_ASSET_PATH.toLowerCase()!=='null')?`../${process.env.REACT_APP_PUBLIC_ASSET_PATH}/assets/images`:"assets/images"),
  siteKey : '6Ldq5_8UAAAAAKd0A9M4zQIogwdCiggK37sqZc7s',
  // 6LfvYGscAAAAAKSWCEgsY-WjDo47QereTxcwgQTP
  // 6LcymAIiAAAAAPFuDZLITSE1jM-6EUVePm1WBCXs
  // 6LcymAIiAAAAACqzpnD1bmnx1gv9I3keCkGqadeI
  // 6LdLDA4iAAAAAIcg6F1cvQuaAnUqkhVzY5qr32k2
  basename : "/nft",
  // uploadPath : "https://ipfs.perma.store/access_token",
  uploadPath : "https://api.pinata.cloud/pinning/pinFileToIPFS",


}
config()
.put("accessNode.api", process.env.REACT_APP_ACCESS_NODE) // Configure FCL's Access Node  
.put("discovery.wallet", process.env.REACT_APP_WALLET_DISCOVERY) // Configure FCL's Wallet Discovery mechanism  
.put("flow.network", "testnet")
.put("app.detail.icon", "https://devnft.disrupt.art/logo512.png")
.put("app.detail.title", "DisruptArt") 
.put("discovery.wallet.method.default","POP/RPC")
